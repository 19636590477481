import { Component, OnInit, Input } from '@angular/core';
import { Avoir } from 'src/app/model/avoir';

@Component({
  selector: 'app-avoir-component',
  templateUrl: './avoir-component.component.html',
  styleUrls: ['./avoir-component.component.sass']
})
export class AvoirComponentComponent implements OnInit {
  @Input() avoir: Avoir;

  coutPedagogique: number;
  coutRestauration: number;
  coutHebergement: number;
  coutTotal: number;


  constructor() { }

  ngOnInit() {
    console.log(this.avoir);
    this.coutPedagogique = 0;
    this.coutRestauration = 0;
    this.coutHebergement = 0;
    this.coutTotal = 0;
    switch (this.avoir.type) {
      case 'pedagogique':
        this.coutPedagogique = this.avoir.montant;
        break;
      case 'restauration':
        this.coutRestauration = this.avoir.montant;
        break;
      case 'hebergement':
        this.coutHebergement = this.avoir.montant;
        break;
      case 'total':
        this.coutTotal = this.avoir.montant;
        break;
      default:
        console.log('Ce type d\'avoir n\'est pas pris en charge : ', this.avoir.type);
    }
  }

}
