import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { format } from 'date-fns';

import { Facture, FactureInterface } from 'src/app/model/facture';
import { Stagiaire } from 'src/app/model/stagiaire';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FactureService {

  constructor(private http: HttpClient) { }

  getFactures(month: string): Observable<FactureInterface[]> {
    return this.http.get<FactureInterface[]>(environment.apiUrl + 'facture/' + month);
  }

  updateNumPgiFacture(factureId: number, numPgi: string) {
    return this.http.put(environment.apiUrl + 'facture', { id: factureId, numPgi: numPgi });
  }

  getFactureDetailsById(factureId: number): Observable<FactureInterface> {
    return this.http.get<FactureInterface>(environment.apiUrl + 'facture/details/' + factureId);
  }

  updateFactureDetail(facture: Facture, inscription: Stagiaire) {
    return this.http.put(environment.apiUrl + 'facture/detail', { facture: facture, inscription: inscription });
  }

  exportFactureDetails(facture: Facture) {
    const workbook = new Workbook();
    const factureSheet = workbook.addWorksheet(facture.unite + facture.date);
    console.log(facture); 

    const factureHeader = ['Numéro PGI', 'Coût Pédagogique', 'Coût Restauration', 'Coût Hébérgement', 'Coût Total', 'Unité', 'Statut', 'Date'];
    factureSheet.addRow(factureHeader);
    factureSheet.addRow([facture.numPgi, facture.coutPedagogique, facture.coutRestauration, facture.coutHebergement, facture.coutTotal, facture.unite, facture.statutLibelle, format(new Date(facture.date), 'dd-MM-yyyy')])

    factureSheet.addRow([]);

    const detailsHeader = ['Nom Prénom', 'NNI', 'Coût Pédagogique', 'Coût Restauration', 'Coût Hébérgement', 'Coût Total', 'Session', 'Tarif', 'Durée', 'Unité', 'Statut'];
    factureSheet.addRow(detailsHeader);
    facture.stagiaires.forEach(stagiaire => {
      factureSheet.addRow([stagiaire.nom + ' ' + stagiaire.prenom, stagiaire.nni, stagiaire.coutPedagogique, stagiaire.coutRestauration, stagiaire.coutHebergement, stagiaire.coutTotal, stagiaire.session, stagiaire.tarif, stagiaire.duree, stagiaire.unite, stagiaire.presence])
    })

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, facture.unite + format(new Date(facture.date), 'dd-MM-yyyy') + '.xlsx');
    });
  }
}
