import { authConfig } from '../../auth.config';
import { OAuthService } from 'angular-oauth2-oidc';
import { OnInit, Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent {

  @Output() login = new EventEmitter<boolean>();

  claims: string;
  error: string;

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  ttl = 1800000;

  constructor(private oauthService: OAuthService, private router: Router, private userService: UserService) {
    // this.configureWithNewConfigApi();
  }

  submit() {
    if (this.form.valid) {
      this.userService.authenticateUser(this.form.controls['username'].value, this.form.controls['password'].value).subscribe(result => {
        if (result === null || result.status !== 'OK') {
          this.error = 'Couple identifiant/mot de passe incorrect';
        } else {
          this.goToFacture();
        }
      });
    }
  }

  goToFacture() {
    const now = new Date();
    localStorage.setItem('logged', (now.getTime() + this.ttl).toString());
    this.login.emit(true);
  }

  /* 
  private configureWithNewConfigApi() {
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tryLogin();
    this.claims = JSON.stringify(this.oauthService.getIdentityClaims(), null, 4);

    if (window.location.href.indexOf('#') > -1) {
      window.location.href = window.location.href.split('#')[0];
    }
  }

  public login() {
    this.oauthService.initImplicitFlow();
    this.oauthService.tryLogin();
  }

  public logout() {
    this.oauthService.logOut();
    this.claims = '';
  } */

}
