// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  gardianUri: 'rec-websso-gardian.myelectricnetwork.com:443',
  gardianClientId: '8a4ab9d8-e564-4d8e-97c3-64c3911b565b',
  gardianClientSecret: '9df59b85-bfd3-4f4a-ba8a-0192b42619aa',
  apiUrl: 'http://15.237.24.88:3000/api/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
