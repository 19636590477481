import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent implements OnInit {

  logged = false;

  constructor() {
  }

  ngOnInit() {
    const loggedTime = localStorage.getItem('logged');
    const now = new Date();
    if (loggedTime !== null && parseInt(loggedTime) >= now.getTime()) {
      this.logged = true;
    } else {
      localStorage.removeItem('logged');
    }
  }

  onLogin(login: Boolean) {
    this.logged = !!login;
  }
}
