import { FactureService } from 'src/app/services/facture/facture.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Facture } from 'src/app/model/facture';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { format, subMonths } from 'date-fns';

@Component({
  selector: 'app-factures',
  templateUrl: './factures.component.html',
  styleUrls: ['./factures.component.sass']
})
export class FacturesComponent implements OnInit, OnDestroy {
  startDate = Date.now();
  datePickerValue = subMonths(Date.now(), 1);

  plaques = new Set();
  plaquesTemp = new Set();
  plaqueValue = '';
  unites = new Set();
  unitesTemp = new Set();
  uniteValue = '';
  regions = new Set();
  regionsTemp = new Set();
  regionValue = '';

  statutFilter: boolean;

  factures: Facture[];

  dataSource: MatTableDataSource<Facture>;
  displayedColumns: string[] = ['unite', 'coutPedagogique', 'coutRestauration', 'coutHebergement', 'coutTotal',
    'plaque', 'region', 'numPgi'];

  filterValues = {};
  filterSelectObj = [];

  numPgi$ = new Subject<string>();
  private updateNumPgi: Subscription;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private router: Router, private factureService: FactureService) {
    this.filterSelectObj = [
      {
        name: 'Plaque',
        columnProp: 'plaque',
        options: this.plaques,
        modelValue: this.plaqueValue
      }, {
        name: 'Unité',
        columnProp: 'unite',
        options: this.unites,
        modelValue: this.uniteValue
      }, {
        name: 'Région',
        columnProp: 'region',
        options: this.regions,
        modelValue: this.regionValue
      }
    ];

    this.updateNumPgi = this.numPgi$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((event: any) => {
        factureService.updateNumPgiFacture(event.factureId, event.numPgi).subscribe();
        // TODO : handle error
        return EMPTY;
      })
    ).subscribe();
  }

  ngOnInit() {
    const localDateStored = new Date(JSON.parse(localStorage.getItem('dateValue')));
    if (localDateStored.getTime() != new Date(null).getTime()) {
      this.datePickerValue = localDateStored;
    }

    this.plaques.add('');
    this.unites.add('');
    this.regions.add('');

    this.statutFilter = false;

    this.getFactures();
  }

  ngOnDestroy() {
    if (this.updateNumPgi) {
      this.updateNumPgi.unsubscribe();
      this.updateNumPgi = null;
    }
  }

  getFactures() {
    this.factureService.getFactures(format(this.datePickerValue, 'yyyyMM')).subscribe(factures => {
      this.factures = factures;
      this.factures.forEach(facture => {
        this.plaquesTemp.add(facture.plaque);
        this.unitesTemp.add(facture.unite);
        this.regionsTemp.add(facture.region);
      });

      const sortedPlaques = Array.from(this.plaquesTemp).sort();
      sortedPlaques.forEach(value => this.plaques.add(value));
      const sortedUnites = Array.from(this.unitesTemp).sort();
      sortedUnites.forEach(value => this.unites.add(value));
      const sortedRegions = Array.from(this.regionsTemp).sort();
      sortedRegions.forEach(value => this.regions.add(value));

      this.dataSource = new MatTableDataSource<Facture>(this.factures);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();
      this.getFilterValues(this);
    });
  }

  filterChange(eventTarget) {
    // eventTarget.id template is 'mat-[XXXXX]-X
    switch (eventTarget.id.split('-')[1]) {
      case 'input':
        this.filterValues[eventTarget.name] = eventTarget.value.trim();
        break;
      case 'select':
        this.filterValues[eventTarget.ngControl.name] = eventTarget.value.trim();
        break;
      case 'button':
        
      default:
        console.log('Ce type de filtre n\'est pas supporté : ', eventTarget.type);
    }

    localStorage.setItem('factureFilterValues', JSON.stringify(this.filterValues));
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  createFilter() {
    const filterFunction = function (data: any, filter: string): boolean {
      const searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      // searchInput is a special filter, not linked to a data column
      let searchInputValue;
      if (searchTerms !== null && searchTerms['searchInput']) {
        searchInputValue = searchTerms['searchInput'];
        delete searchTerms['searchInput'];
      }

      const nameSearch = () => {
        if (isFilterSet) {
          // If one of the filters don't match, the data is filtered
          for (const col in searchTerms) {
            if (data[col].toString() !== searchTerms[col].toString()) {
              return false;
            }
          }
          // If the search input is filled, one of the data elements need to match with it
          if (searchInputValue !== undefined) {
            for (const dataCol in data) {
              if (data[dataCol] !== null && data[dataCol].toString().toLowerCase().includes(searchInputValue.toString().toLowerCase())) {
                return true;
              }
            }
            return false;
          }
          return true;
        } else {
          return true;
        }
      };
      return nameSearch();
    };
    return filterFunction;
  }

  closeDatePicker(eventData: any, dp?: any) {
    this.datePickerValue = eventData;
    localStorage.setItem('dateValue', JSON.stringify(this.datePickerValue));
    this.getFactures();
    dp.close();
  }

  getFilterValues(self) {
    const localStorageFilterValues = JSON.parse(localStorage.getItem('factureFilterValues'));

    if (localStorageFilterValues !== null) {
      for (const value in localStorageFilterValues) {
        // searchInput create a bug, temporary exclusion
        if (value !== 'searchInput') {
          const filter = self.filterSelectObj.find(filterToFind => filterToFind.columnProp === value);
          filter.modelValue = localStorageFilterValues[value];
        }
      }

      self.filterValues = localStorageFilterValues;
      self.dataSource.filter = JSON.stringify(localStorageFilterValues);
    }
  }
}
