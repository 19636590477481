import { FactureService } from 'src/app/services/facture/facture.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Params } from '@angular/router';
import { Facture } from 'src/app/model/facture';
import { Stagiaire } from 'src/app/model/stagiaire';
import { Avoir } from 'src/app/model/avoir';

@Component({
  selector: 'app-detail-facture',
  templateUrl: './detail-facture.component.html',
  styleUrls: ['./detail-facture.component.sass']
})
export class DetailFactureComponent implements OnInit {

  facture: Facture;

  avoirs: Avoir[];
  avoirsTiles: Tile[] = [];

  dataSource: MatTableDataSource<Stagiaire>;
  displayedColumns: string[] = ['facturable', 'nomPrenom', 'nni', 'coutPedagogique', 'coutRestauration', 'coutHebergement',
    'session', 'tarif', 'duree', 'unite', 'statut'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private route: ActivatedRoute, private factureService: FactureService) { }

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      if (params['id'] !== undefined) {
        const id = +params['id'];

        this.factureService.getFactureDetailsById(id).subscribe(facture => {
          this.facture = facture;
          console.log(this.facture);

          if (facture.numPgi !== null && facture.numPgi !== '') {
            this.displayedColumns.shift();
          }

          this.dataSource = new MatTableDataSource(facture.stagiaires);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });

        this.avoirs = this.getAvoirs(id);
        // TODO : à supprimer en faisant marcher avoir-component
        this.getAvoirsTiles(this.avoirs);
      } else {
        // TODO : Error to handle
      }
    });
  }

  searchFactureDetail(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // TODO : refacto en faisant marcher avoir-component
  getAvoirsTiles(avoirs: Avoir[]) {
    avoirs.forEach(avoir => {
      this.avoirsTiles.push({ text: avoir.numPgi, cols: 1, color: 'black' });
      switch (avoir.type) {
        case 'pedagogique':
          this.avoirsTiles.push({ text: `- ${avoir.montant.toString()} €`, cols: 1, color: 'red' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          break;
        case 'restauration':
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: `- ${avoir.montant.toString()} €`, cols: 1, color: 'red' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          break;
        case 'hebergement':
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: `- ${avoir.montant.toString()} €`, cols: 1, color: 'red' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          break;
        case 'total':
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: '0 €', cols: 1, color: 'black' });
          this.avoirsTiles.push({ text: `- ${avoir.montant.toString()} €`, cols: 1, color: 'red' });
          break;
        default:
          console.log('Ce type d\'avoir n\'est pas pris en charge : ', avoir.type);
      }
      this.avoirsTiles.push({ text: avoir.commentaire, cols: 3, color: 'black' });
    });
  }

  // TODO FACTU-37 : Get avoirs by call to service
  getAvoirs(factureId: number) {
    const avoirs = [];

    return avoirs;
  }

  switchFacturable(stagiaire: Stagiaire) {
    const stagiaireIndex = this.facture.stagiaires.indexOf(stagiaire);

    stagiaire.facturable = !stagiaire.facturable;
    if (stagiaire.facturable) {
      this.facture.coutPedagogique = this.facture.coutPedagogique + stagiaire.coutPedagogique;
      this.facture.coutRestauration = this.facture.coutRestauration + stagiaire.coutRestauration;
      this.facture.coutHebergement = this.facture.coutHebergement + stagiaire.coutHebergement;
      this.facture.coutTotal = this.facture.coutTotal + stagiaire.coutTotal;
    } else {
      this.facture.coutPedagogique = this.facture.coutPedagogique - stagiaire.coutPedagogique;
      this.facture.coutRestauration = this.facture.coutRestauration - stagiaire.coutRestauration;
      this.facture.coutHebergement = this.facture.coutHebergement - stagiaire.coutHebergement;
      this.facture.coutTotal = this.facture.coutTotal - stagiaire.coutTotal;
    }

    this.facture.stagiaires[stagiaireIndex] = stagiaire;
    console.log(this.facture);

    this.factureService.updateFactureDetail(this.facture, stagiaire).subscribe();
  }

  exportFacture() {
    this.factureService.exportFactureDetails(this.facture);
  }
}

export interface Tile {
  color: string;
  cols: number;
  text: string;
}
