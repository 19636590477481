import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalReportingComponent } from 'src/app/components/global-reporting-component/global-reporting.component';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.sass']
})
export class ReportingComponent implements OnInit {

  tabName: string;

  @ViewChild(GlobalReportingComponent, { static: true }) globalComponent: GlobalReportingComponent;

  constructor() { }

  ngOnInit() {

  }

}
