import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-infos-header',
  templateUrl: './user-infos-header.component.html',
  styleUrls: ['./user-infos-header.component.sass']
})
export class UserInfosHeaderComponent implements OnInit {
  userName = 'User';

  constructor() { }

  ngOnInit() {
  }

}
