import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reductions',
  templateUrl: './reductions.component.html',
  styleUrls: ['./reductions.component.sass']
})
export class ReductionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
