import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  postFile(fileToUpload: File, type: string) {
    const headers = new HttpHeaders({
      encType: 'multipart/form-data'
    });

    const formData: FormData = new FormData();
    formData.append('files', fileToUpload, fileToUpload.name);
    return this.http.post(environment.apiUrl + 'upload/' + type, formData, { headers: headers });

    /* return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          switch (xhr.status) {
            case 200:
              resolve(<any>JSON.parse(xhr.response));
              break;
            default:
              reject(xhr.response);
              break;
          }
        }
      };

      xhr.open('POST', environment.apiUrl + 'upload/' + type, true);

      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      xhr.send(formData);
    });
    */
  }

  downloadStaticFile(filename: string) {
    return this.http.get('assets/' + filename, {responseType: 'blob'});
  }
}
