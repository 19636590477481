import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import * as fs from 'file-saver';
import { FileService } from 'src/app/services/file/file.service';

@Component({
  selector: 'app-aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.sass']
})
export class AideComponent implements OnInit {

  manualFileName = 'MAN_MaFactuDFP_Utilisateur.pdf';

  constructor(private http: HttpClient, private fileService: FileService) { }

  ngOnInit() {
  }

  downloadManual() {
    this.fileService.downloadStaticFile(this.manualFileName).subscribe(file => {
      const blob = new Blob([file], { type: 'text/json; charset=utf-8' });
      fs.saveAs(blob, this.manualFileName);
    });
  }

}
