import { Component, OnInit } from '@angular/core';

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { ReportingService } from 'src/app/services/reporting/reporting.service';

@Component({
  selector: 'app-yearly-reporting',
  templateUrl: './yearly-reporting.component.html',
  styleUrls: ['./yearly-reporting.component.sass']
})
export class YearlyReportingComponent implements OnInit {
  now = new Date();

  datePickerValue: Date = new Date(this.now.getUTCFullYear(), 0, 1);

  constructor(private reportingService: ReportingService) { }

  ngOnInit() {
  }

  closeDatePicker(eventData: any, dp?: any) {
    this.datePickerValue = eventData;
    dp.close();
  }

  exportSuiviAnnuel() {
    this.reportingService.getYearlyReporting(this.datePickerValue).subscribe(reportingValues => {
      reportingValues.map(value => {
        value.date = new Date(value.date);
        value.nombreStagiaires = value.nombreStagiaires;
        return value;
      });

      const annualValues = {
        ENEDIS: {
          nombreStagiaires: 0,
          coutTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          volumeHeure: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        SEI: {
          nombreStagiaires: 0,
          coutTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          volumeHeure: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        EXTERNE: {
          nombreStagiaires: 0,
          coutTotal: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          volumeHeure: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      };

      reportingValues.forEach(value => {
        console.log(value.date.getUTCMonth());
        annualValues[value.societe].nombreStagiaires = annualValues[value.societe].nombreStagiaires + value.nombreStagiaires;
        annualValues[value.societe].coutTotal[value.date.getUTCMonth()] = value.coutTotal;
        annualValues[value.societe].volumeHeure[value.date.getUTCMonth()] = value.volumeHeure;
      });

      const workbook = new Workbook();
      const reportingSheet = workbook.addWorksheet('Reporting annuel');

      reportingSheet.addRow([' 2019 - Suivi mensuel des volumes de formation dispensés par DFP']);
      reportingSheet.addRow([]);

      reportingSheet.addRow(['Volume de stagiaires au global', 'Année ' + this.datePickerValue.getFullYear()]);
      reportingSheet.addRow(['ENEDIS', annualValues.ENEDIS.nombreStagiaires]);
      reportingSheet.addRow(['SEI', annualValues.SEI.nombreStagiaires]);
      reportingSheet.addRow(['EXTERNE', annualValues.EXTERNE.nombreStagiaires]);
      reportingSheet.addRow(['TOTAL', annualValues.ENEDIS.nombreStagiaires + annualValues.SEI.nombreStagiaires + annualValues.EXTERNE.nombreStagiaires]);

      reportingSheet.addRow([]);

      reportingSheet.addRow(['Volume d\'heures de formation par client', 'JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE']);
      reportingSheet.addRow(['ENEDIS', annualValues.ENEDIS.volumeHeure[0], annualValues.ENEDIS.volumeHeure[1], annualValues.ENEDIS.volumeHeure[2], annualValues.ENEDIS.volumeHeure[3], annualValues.ENEDIS.volumeHeure[4], annualValues.ENEDIS.volumeHeure[5], annualValues.ENEDIS.volumeHeure[6], annualValues.ENEDIS.volumeHeure[7], annualValues.ENEDIS.volumeHeure[8], annualValues.ENEDIS.volumeHeure[9], annualValues.ENEDIS.volumeHeure[10], annualValues.ENEDIS.volumeHeure[11]]);
      reportingSheet.addRow(['SEI', annualValues.SEI.volumeHeure[0], annualValues.SEI.volumeHeure[1], annualValues.SEI.volumeHeure[2], annualValues.SEI.volumeHeure[3], annualValues.SEI.volumeHeure[4], annualValues.SEI.volumeHeure[5], annualValues.SEI.volumeHeure[6], annualValues.SEI.volumeHeure[7], annualValues.SEI.volumeHeure[8], annualValues.SEI.volumeHeure[9], annualValues.SEI.volumeHeure[10], annualValues.SEI.volumeHeure[11]]);
      reportingSheet.addRow(['EXTERNE', annualValues.EXTERNE.volumeHeure[0], annualValues.EXTERNE.volumeHeure[1], annualValues.EXTERNE.volumeHeure[2], annualValues.EXTERNE.volumeHeure[3], annualValues.EXTERNE.volumeHeure[4], annualValues.EXTERNE.volumeHeure[5], annualValues.EXTERNE.volumeHeure[6], annualValues.EXTERNE.volumeHeure[7], annualValues.EXTERNE.volumeHeure[8], annualValues.EXTERNE.volumeHeure[9], annualValues.EXTERNE.volumeHeure[10], annualValues.EXTERNE.volumeHeure[11]]);
      reportingSheet.addRow(['TOTAL', annualValues.ENEDIS.volumeHeure[0] + annualValues.SEI.volumeHeure[0] + annualValues.EXTERNE.volumeHeure[0], annualValues.ENEDIS.volumeHeure[1] + annualValues.SEI.volumeHeure[1] + annualValues.EXTERNE.volumeHeure[1], annualValues.ENEDIS.volumeHeure[2] + annualValues.SEI.volumeHeure[2] + annualValues.EXTERNE.volumeHeure[2], annualValues.ENEDIS.volumeHeure[3] + annualValues.SEI.volumeHeure[3] + annualValues.EXTERNE.volumeHeure[3], annualValues.ENEDIS.volumeHeure[4] + annualValues.SEI.volumeHeure[4] + annualValues.EXTERNE.volumeHeure[4], annualValues.ENEDIS.volumeHeure[5] + annualValues.SEI.volumeHeure[5] + annualValues.EXTERNE.volumeHeure[5], annualValues.ENEDIS.volumeHeure[6] + annualValues.SEI.volumeHeure[6] + annualValues.EXTERNE.volumeHeure[6], annualValues.ENEDIS.volumeHeure[7] + annualValues.SEI.volumeHeure[7] + annualValues.EXTERNE.volumeHeure[7], annualValues.ENEDIS.volumeHeure[8] + annualValues.SEI.volumeHeure[8] + annualValues.EXTERNE.volumeHeure[8], annualValues.ENEDIS.volumeHeure[9] + annualValues.SEI.volumeHeure[9] + annualValues.EXTERNE.volumeHeure[9], annualValues.ENEDIS.volumeHeure[10] + annualValues.SEI.volumeHeure[10] + annualValues.EXTERNE.volumeHeure[10], annualValues.ENEDIS.volumeHeure[11] + annualValues.SEI.volumeHeure[11] + annualValues.EXTERNE.volumeHeure[11]]);

      reportingSheet.addRow([]);

      reportingSheet.addRow(['Volume d\'heures facturées en Euros', 'JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE']);
      reportingSheet.addRow(['ENEDIS', annualValues.ENEDIS.coutTotal[0], annualValues.ENEDIS.coutTotal[1], annualValues.ENEDIS.coutTotal[2], annualValues.ENEDIS.coutTotal[3], annualValues.ENEDIS.coutTotal[4], annualValues.ENEDIS.coutTotal[5], annualValues.ENEDIS.coutTotal[6], annualValues.ENEDIS.coutTotal[7], annualValues.ENEDIS.coutTotal[8], annualValues.ENEDIS.coutTotal[9], annualValues.ENEDIS.coutTotal[10], annualValues.ENEDIS.coutTotal[11]]);
      reportingSheet.addRow(['SEI', annualValues.SEI.coutTotal[0], annualValues.SEI.coutTotal[1], annualValues.SEI.coutTotal[2], annualValues.SEI.coutTotal[3], annualValues.SEI.coutTotal[4], annualValues.SEI.coutTotal[5], annualValues.SEI.coutTotal[6], annualValues.SEI.coutTotal[7], annualValues.SEI.coutTotal[8], annualValues.SEI.coutTotal[9], annualValues.SEI.coutTotal[10], annualValues.SEI.coutTotal[11]]);
      reportingSheet.addRow(['EXTERNE', annualValues.EXTERNE.coutTotal[0], annualValues.EXTERNE.coutTotal[1], annualValues.EXTERNE.coutTotal[2], annualValues.EXTERNE.coutTotal[3], annualValues.EXTERNE.coutTotal[4], annualValues.EXTERNE.coutTotal[5], annualValues.EXTERNE.coutTotal[6], annualValues.EXTERNE.coutTotal[7], annualValues.EXTERNE.coutTotal[8], annualValues.EXTERNE.coutTotal[9], annualValues.EXTERNE.coutTotal[10], annualValues.EXTERNE.coutTotal[11]]);
      reportingSheet.addRow(['TOTAL', annualValues.ENEDIS.coutTotal[0] + annualValues.SEI.coutTotal[0] + annualValues.EXTERNE.coutTotal[0], annualValues.ENEDIS.coutTotal[1] + annualValues.SEI.coutTotal[1] + annualValues.EXTERNE.coutTotal[1], annualValues.ENEDIS.coutTotal[2] + annualValues.SEI.coutTotal[2] + annualValues.EXTERNE.coutTotal[2], annualValues.ENEDIS.coutTotal[3] + annualValues.SEI.coutTotal[3] + annualValues.EXTERNE.coutTotal[3], annualValues.ENEDIS.coutTotal[4] + annualValues.SEI.coutTotal[4] + annualValues.EXTERNE.coutTotal[4], annualValues.ENEDIS.coutTotal[5] + annualValues.SEI.coutTotal[5] + annualValues.EXTERNE.coutTotal[5], annualValues.ENEDIS.coutTotal[6] + annualValues.SEI.coutTotal[6] + annualValues.EXTERNE.coutTotal[6], annualValues.ENEDIS.coutTotal[7] + annualValues.SEI.coutTotal[7] + annualValues.EXTERNE.coutTotal[7], annualValues.ENEDIS.coutTotal[8] + annualValues.SEI.coutTotal[8] + annualValues.EXTERNE.coutTotal[8], annualValues.ENEDIS.coutTotal[9] + annualValues.SEI.coutTotal[9] + annualValues.EXTERNE.coutTotal[9], annualValues.ENEDIS.coutTotal[10] + annualValues.SEI.coutTotal[10] + annualValues.EXTERNE.coutTotal[10], annualValues.ENEDIS.coutTotal[11] + annualValues.SEI.coutTotal[11] + annualValues.EXTERNE.coutTotal[11]]);

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, 'Reporting annuel ' + this.datePickerValue.getUTCFullYear() + '.xlsx');
      });

    });
  }
}
