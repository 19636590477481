import { ReportingComponent } from './pages/reporting/reporting.component';
import { AideComponent } from './pages/aide/aide.component';
import { AppComponent } from './app.component';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { OAuthModule } from 'angular-oauth2-oidc';

import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FacturesComponent } from './pages/factures/factures.component';
import { DetailFactureComponent } from './pages/factures/detail-facture/detail-facture.component';
import { ReductionsComponent } from './pages/reductions/reductions.component';
import { UserInfosHeaderComponent } from './components/user-infos-header/user-infos-header.component';
import { LoginComponent } from 'src/app/components/login-component/login.component';

import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AvoirComponentComponent } from './components/avoir-component/avoir-component.component';
import { GlobalReportingComponent } from './components/global-reporting-component/global-reporting.component';
import { YearlyReportingComponent } from './components/yearly-reporting/yearly-reporting.component';
import { UploadComponent } from './pages/upload/upload.component';

registerLocaleData(localeFr);

const appRoutes: Routes = [
  { path: '', component: FacturesComponent },
  { path: 'reporting', component: ReportingComponent },
  { path: 'factures', component: FacturesComponent },
  { path: 'factures/:id', component: DetailFactureComponent },
  { path: 'aide', component: AideComponent },
  { path: 'upload', component: UploadComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    FacturesComponent,
    DetailFactureComponent,
    AideComponent,
    ReductionsComponent,
    ReportingComponent,
    UserInfosHeaderComponent,
    AvoirComponentComponent,
    GlobalReportingComponent,
    LoginComponent,
    YearlyReportingComponent,
    UploadComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatCardModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
