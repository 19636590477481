export class Avoir {
    id: number;
    numPgi: string;
    type: string;
    montant: number;
    commentaire: string;

    constructor(id: number, numPgi: string, type: string, montant: number, commentaire: string) {
        this.id = id;
        this.numPgi = numPgi;
        this.type = type;
        this.montant = montant;
        this.commentaire = commentaire;
    }
}