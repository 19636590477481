import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { format, subMonths } from 'date-fns';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { ReportingService } from 'src/app/services/reporting/reporting.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Reporting } from 'src/app/model/reporting';

@Component({
  selector: 'app-global-reporting',
  templateUrl: './global-reporting.component.html',
  styleUrls: ['./global-reporting.component.sass']
})
export class GlobalReportingComponent implements OnInit {
  now = new Date();

  startDatePickerValue: Date = new Date(this.now.getUTCFullYear(), 0, 1);
  endDatePickerValue: Date = subMonths(this.now, 1);

  reportingFactures: Reporting[];

  dataSource: MatTableDataSource<Reporting>;
  displayedColumns: string[] = ['unite', 'numPgi', 'coutPedagogique', 'coutRestauration', 'coutTotal', 'plaque', 'region',
  'volumeHeures', 'volumeStagiaires'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private reportingService: ReportingService) { }

  ngOnInit() {
    this.getFactures();
  }

  getFactures() {
    this.reportingService.getGlobalFacture(this.startDatePickerValue, this.endDatePickerValue).subscribe(reportingFactures => {
      this.reportingFactures = reportingFactures;
      console.log(this.reportingFactures);

      this.dataSource = new MatTableDataSource<Reporting>(this.reportingFactures);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  closeStartDatePicker(eventData: any, dp?: any) {
    this.startDatePickerValue = eventData;
    this.getFactures();
    dp.close();
  }

  closeEndDatePicker(eventData: any, dp?: any) {
    this.endDatePickerValue = eventData;
    this.getFactures();
    dp.close();
  }

  exportSuiviGlobal() {
    const workbook = new Workbook();
    const reportingSheet = workbook.addWorksheet('Reporting global');

    const factureHeader = ['ID', 'Unités', 'Code unité', 'Date', 'Statut', 'Coût Pédagogique', 'Coût Restauration', 'Volume en heure', 'Volume en stagiaire'];
    reportingSheet.addRow(factureHeader);
    this.reportingFactures.forEach(element => {
      reportingSheet.addRow([element.id, element.libelleUnite, element.codeUnite, element.date, element.statut, element.coutPedagogique, element.coutRestauration, element.volumeHeure, element.nombreStagiaires]);
    })

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, format(new Date(this.startDatePickerValue), 'dd-MM-yyyy') + '-' + format(new Date(this.endDatePickerValue), 'dd-MM-yyyy') + '.xlsx');
    });
  }

}
