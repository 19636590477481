import { Component, OnInit } from '@angular/core';
import { FileService } from 'src/app/services/file/file.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.sass']
})
export class UploadComponent implements OnInit {

  stagiairesToUpload: File = null;
  sessionsToUpload: File = null;
  inscriptionsToUpload: File = null;

  constructor(private fileService: FileService) { }

  ngOnInit() {
  }

  handleStagiairesInput(files: FileList) {
    this.stagiairesToUpload = files.item(0);
    this.uploadFile(files.item(0), 'stagiaires');
  }

  handleSessionsInput(files: FileList) {
    this.sessionsToUpload = files.item(0);
    this.uploadFile(files.item(0), 'sessions');
  }

  handleInscriptionsInput(files: FileList) {
    this.inscriptionsToUpload = files.item(0);
    this.uploadFile(files.item(0), 'inscriptions');
  }

  uploadFile(fileToUpload: File, type: string) {
    return this.fileService.postFile(fileToUpload, type).subscribe(data => {
        console.log('ok');
      }, error => {
        console.log(error);
      });
  }

}
