import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { format } from 'date-fns';

import { environment } from 'src/environments/environment';
import { ReportingInterface } from 'src/app/model/reporting';
import { YearlyReportingInterface } from 'src/app/model/yearlyReporting';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private http: HttpClient) { }

  getGlobalFacture(dateDebut: Date, dateFin: Date) {
    return this.http.get<ReportingInterface[]>(environment.apiUrl + 'reporting/global/' + format(dateDebut, 'yyyyMM') + '-' + format(dateFin, 'yyyyMM'));
  }

  getYearlyReporting(date: Date) {
    return this.http.get<YearlyReportingInterface[]>(environment.apiUrl + 'reporting/annuel/' + format(date, 'yyyyy'));
  }
}
